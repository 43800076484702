import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useField } from '@unform/core';

interface IDegreeProps {
  isExternalLoading?: boolean;
  name: string;
  onChange?: (value: IDegree) => void; // Adicionar o callback para mudanças
  copyFrom?: IDegree; // Dados do grau "longe"
}

export interface IDegree {
  esf: number;
  cil: number;
  eix: number;
  dnp: number;
  alt: number;
}

export const Degree: React.FC<IDegreeProps> = ({
  isExternalLoading = false,
  name,
  onChange,
  copyFrom,
}) => {
  const { fieldName, registerField, defaultValue } = useField(name);

  // Inicializar o estado com valores numéricos
  const [degree, setDegree] = useState<IDegree>({
    esf: defaultValue?.esf || copyFrom?.esf,
    cil: defaultValue?.cil || copyFrom?.cil,
    eix: defaultValue?.eix || copyFrom?.eix,
    dnp: defaultValue?.dnp || copyFrom?.dnp,
    alt: defaultValue?.alt || copyFrom?.alt,
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: degree,
      getValue: () => degree, // Retorna o objeto degree diretamente
      setValue: (ref, value: IDegree) => {
        setDegree({
          esf: value?.esf,
          cil: value?.cil,
          eix: value?.eix,
          dnp: value?.dnp,
          alt: value?.alt,
        });
      },
    });
  }, [fieldName, registerField, degree]);

  // Atualiza os valores ao mudar `copyFrom`
  useEffect(() => {
    if (copyFrom) {
      setDegree(copyFrom);
    }
  }, [copyFrom]); // Dispara somente quando `copyFrom` mudar

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = e.target;
    setDegree((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0, // Converter o valor para número
    }));
  };

  // Disparar onChange sempre que degree mudar
  useEffect(() => {
    if (onChange) {
      onChange(degree);
    }
  }, [degree, onChange]);

  return (
    <Grid container item direction="row">
      {isExternalLoading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        <>
          <Grid item xs={3} >
            <TextField
              label="Esf."
              name="esf"
              size="small"
              value={degree.esf}
              onChange={handleChange}
              type="number"
              inputProps={{ step: 0.25 }} // Define o incremento de 0,25
              InputLabelProps={{
                shrink: true, // Garante que o label fique "encolhido" quando necessário
              }}
              InputProps={{
                style: {
                  color: degree.esf > 0 ? 'green' : 'red', // Define a cor baseada no valor
                },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Cil."
              name="cil"
              size="small"
              value={degree.cil}
              onChange={handleChange}
              type="number"
              inputProps={{ step: 0.25 }}
              InputLabelProps={{
                shrink: true, // Garante que o label fique "encolhido" quando necessário
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Eixo"
              name="eix"
              size="small"
              value={degree.eix}
              onChange={handleChange}
              type="number"
              inputProps={{ step: 30 }}
              InputLabelProps={{
                shrink: true, // Garante que o label fique "encolhido" quando necessário
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              label="DNP"
              name="dnp"
              size="small"
              value={degree.dnp}
              onChange={handleChange}
              type="number"
              inputProps={{ step: 10 }}
              InputLabelProps={{
                shrink: true, // Garante que o label fique "encolhido" quando necessário
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              label="ALT"
              name="alt"
              size="small"
              value={degree.alt}
              onChange={handleChange}
              type="number"
              inputProps={{ step: 10 }}
              InputLabelProps={{
                shrink: true, // Garante que o label fique "encolhido" quando necessário
              }}
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
