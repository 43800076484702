import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDebounce } from '../../../shared/hooks';
import { DashboardsService } from '../../../shared/services/api/dashboars/DashboardsService';
import { TablePayments } from './overview/TablePayments';
import { TableExpenses } from './overview/TableExpenses';
import { ChartSales } from './overview/ChartSales';
import { ChartPayments } from './overview/ChartPayments';

export const DashboardOverview: React.FC<{
  search: string;
  date: string;
  dateEnd: string;
}> = ({ search, date, dateEnd }) => {
  const { debounce } = useDebounce();
  const [sales, setSales] = useState([]);
  const [payments, setPayments] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      Promise.all([
        DashboardsService.getSalesReport(search, date, dateEnd),
        DashboardsService.getPaymentsReport(search, date),
        DashboardsService.getExpensesReport(search, date),
      ]).then(([salesRes, paymentsRes, expensesRes]) => {
        setIsLoading(false);
        if (!(salesRes instanceof Error)) {
          setSales(salesRes.data);
        } else {
          console.error('Erro ao buscar vendas:', salesRes.message);
        }

        if (!(paymentsRes instanceof Error)) {
          setPayments(paymentsRes.data);
        } else {
          console.error('Erro ao buscar pagamentos:', paymentsRes.message);
        }

        if (!(expensesRes instanceof Error)) {
          setExpenses(expensesRes.data);
        } else {
          console.error('Erro ao buscar despesas:', expensesRes.message);
        }
      });
    });
  }, [search, date, dateEnd]);

  return (
    <Grid item container spacing={2}>
      <Grid item md={12}>
        <ChartSales
          data={sales}
          totalCount={sales.length}
          isExternalLoading={isLoading}
        />
      </Grid>
      <Grid item md={12}>
        <ChartPayments
          data={payments}
          isExternalLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
