import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Paper,
  Tab,
  TextField,
  Typography,
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { LayoutBaseDePagina } from '../../shared/layouts';
import { PatientsService } from '../../shared/services/api/patients/PatientsService';
import { LocationsService } from '../../shared/services/api/locations/LocationsService';
import { ExamsService } from '../../shared/services/api/exams/ExamsService';
import { LocationDashboard } from './components/LocationDashboard';
import { useSearchParams } from 'react-router-dom';
import { DashboardOverview } from './components/DashboardOverview';

export const Dashboard = () => {
  const [value, setValue] = useState('1');

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [totalCountPatients, setTotalCountPatients] = useState(0);

  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [totalCountLocations, setTotalCountLocations] = useState(0);

  const [isLoadingExams, setIsLoadingExams] = useState(true);
  const [totalCountExams, setTotalCountExams] = useState(0);

  const orderBy = useMemo(
    () =>
      (searchParams.get('orderBy') as
        | 'id'
        | 'local'
        | 'totalCountSell'
        | 'totalPayment'
        | 'totalExpense'
        | 'grossProfit'
        | 'netProfit'
        | 'receivables') || 'id',
    [searchParams]
  );
  const orderDirection = useMemo(
    () => (searchParams.get('orderDirection') as 'asc' | 'desc') || 'asc',
    [searchParams]
  );

  const search = useMemo(
    () => searchParams.get('search') || '',
    [searchParams]
  );
  const date = useMemo(() => searchParams.get('date') || '', [searchParams]);
  const dateEnd = useMemo(
    () => searchParams.get('dateEnd') || '',
    [searchParams]
  );

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoadingLocations(true);
    setIsLoadingPatients(true);

    PatientsService.getAll(1).then((result) => {
      setIsLoadingPatients(false);

      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCountPatients(result.totalCount);
      }
    });
    LocationsService.getAll(1).then((result) => {
      setIsLoadingLocations(false);

      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCountLocations(result.totalCount);
      }
    });
    ExamsService.getAll(1).then((result) => {
      setIsLoadingExams(false);

      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCountExams(result.totalCount);
      }
    });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleFilterChange = (field: string, value: any) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [field]: value,
      page: '1',
    });
  };

  return (
    <LayoutBaseDePagina titulo="Relatórios" barraDeFerramentas={false}>
      <Box
        width="100%"
        display="flex"
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Grid container margin={2}>
          <Grid item paddingRight={2} xs={2}>
            <TextField
              label="Busca"
              value={search}
              onChange={(e) => handleFilterChange('search', e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item paddingRight={2} xs={2}>
            <TextField
              type="date"
              label="Data Inicio"
              value={date}
              onChange={(e) => handleFilterChange('date', e.target.value)}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item paddingRight={2} xs={2}>
            <TextField
              type="date"
              label="Data Fim"
              value={dateEnd}
              onChange={(e) => handleFilterChange('dateEnd', e.target.value)}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item paddingRight={2} xs={2}>
            <TextField
              select
              value={orderBy}
              onChange={(e) => handleFilterChange('orderBy', e.target.value)}
              size="small"
              fullWidth
            >
              <MenuItem value="id">ID</MenuItem>
              <MenuItem value="local">Local</MenuItem>
              <MenuItem value="totalCountSell">Vendas</MenuItem>
              <MenuItem value="totalPayment">Pago</MenuItem>
              <MenuItem value="totalExpense">Despesas</MenuItem>
              <MenuItem value="grossProfit">Lucro Bruto</MenuItem>
              <MenuItem value="netProfit">Lucro Liquido</MenuItem>
              <MenuItem value="receivables">Restante</MenuItem>
            </TextField>
          </Grid>
          <Grid item paddingRight={2} xs={2}>
            <TextField
              select
              value={orderDirection}
              onChange={(e) =>
                handleFilterChange('orderDirection', e.target.value)
              }
              size="small"
              fullWidth
            >
              <MenuItem value="asc">Ascendente</MenuItem>
              <MenuItem value="desc">Descendente</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Box width="100%" display="flex">
        <Grid container margin={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="left">
                    Pacientes:
                  </Typography>

                  <Box
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isLoadingPatients && (
                      <Typography variant="h4">{totalCountPatients}</Typography>
                    )}
                    {isLoadingPatients && (
                      <Typography variant="h6">Carregando...</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="left">
                    Locais:
                  </Typography>

                  <Box
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isLoadingLocations && (
                      <Typography variant="h4">
                        {totalCountLocations}
                      </Typography>
                    )}
                    {isLoadingLocations && (
                      <Typography variant="h6">Carregando...</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="left">
                    Exames:
                  </Typography>

                  <Box
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isLoadingExams && (
                      <Typography variant="h4">{totalCountExams}</Typography>
                    )}
                    {isLoadingExams && (
                      <Typography variant="h6">Carregando...</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            container
            width="100%"
            display="flex"
            component={Paper}
            variant="outlined"
            sx={{ m: 1, width: 'auto' }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Locais" value="1" />
                  <Tab label="Data" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid item xs={12}>
                  <LocationDashboard
                    search={search}
                    date={date}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid item xs={12}>
                  <DashboardOverview
                    search={search}
                    date={date}
                    dateEnd={dateEnd}
                  />
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </LayoutBaseDePagina>
  );
};
