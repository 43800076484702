import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  IListExam,
  ExamsService,
} from '../../shared/services/api/exams/ExamsService';
import { ToolsListing } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { Environment } from '../../shared/environment';
import { useDebounce } from '../../shared/hooks';
import dayjs from 'dayjs';
import { SortTableHeader } from '../components/SortTableHeader';
import { FilterBar } from '../components/FilterBar';

export const ListExams: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListExam[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const filters = [
    { name: 'date', label: 'Data inicial', type: 'date' as const },
    { name: 'dateEnd', label: 'Data final', type: 'date' as const },
  ];

  const initialValues = {
    date: '',
    dateEnd: '',
  };

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const orderBy = useMemo(
    () =>
      (searchParams.get('orderBy') as
        | 'id'
        | 'date'
        | 'name'
        | 'local'
        | 'description') || 'id',
    [searchParams]
  );
  const orderDirection = useMemo(
    () => (searchParams.get('orderDirection') as 'asc' | 'desc') || 'asc',
    [searchParams]
  );

  useEffect(() => {
    setIsLoading(true);

    // Atualiza os parâmetros de pesquisa incluindo o novo filtro
    debounce(() => {
      const newSearchParams = {
        page,
        search,
        orderBy,
        orderDirection,
        date: searchParams.get('date') || '',
        dateEnd: searchParams.get('dateEnd') || '',
      };

      ExamsService.getAll(
        newSearchParams.page,
        newSearchParams.search,
        newSearchParams.orderBy,
        newSearchParams.orderDirection,
        newSearchParams.date,
        newSearchParams.dateEnd,
     
      ).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [
    searchParams, // Isso garante que os filtros são observados
    page,
    orderBy,
    orderDirection,
  ]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ExamsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setRows((oldRows) => [
            ...oldRows.filter((oldRow) => oldRow.id !== id),
          ]);
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  const handleSortChange = (
    newOrderBy: 'id' | 'date' | 'name' | 'local' | 'description'
  ) => {
    const isAsc = orderBy === newOrderBy && orderDirection === 'asc';
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      orderBy: newOrderBy,
      orderDirection: isAsc ? 'desc' : 'asc',
      page: '1',
    });
  };

  const applyFilters = (values: Record<string, string | number | null>) => {
    const newSearchParams: Record<string, string> = {
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    };

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        newSearchParams[key] = String(value);
      } else {
        delete newSearchParams[key];
      }
    });

    setSearchParams(newSearchParams);
  };

  return (
    <LayoutBaseDePagina
      titulo="Exames"
      barraDeFerramentas={
        <ToolsListing
          mostrarInputBusca
          textoDaBusca={search}
          textoBotaoNovo="Nova"
          aoClicarEmNovo={() => navigate('/exams/detail/nova')}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams({ search: texto, page: '1' }, { replace: true })
          }
        />
      }
    >
      {/* Chamando o FilterBar */}
      <FilterBar
        filters={filters}
        initialValues={initialValues}
        onApplyFilters={applyFilters}
        isLoading={isLoading}
      />

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={100}>Ações</TableCell>
              <SortTableHeader
                orderBy={orderBy}
                orderDirection={orderDirection}
                onSortChange={handleSortChange}
                columns={[
                  { label: 'ID', value: 'id' },
                  { label: 'Data', value: 'date' },
                  { label: 'Nome', value: 'name' },
                  { label: 'Local', value: 'local' },
                  { label: 'Descrição', value: 'description' },
                ]}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton size="small" onClick={() => handleDelete(row.id)}>
                    <Icon>delete</Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/exams/detail/${row.id}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{dayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.patient?.name}</TableCell>
                <TableCell>{row.location?.local}</TableCell>
                <TableCell>
                  {row.description}
                  {/* 
                {Object.entries(row.diagnosis).map(([key, value]) => (
                    <Box
                      key={key}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: value ? 'success.main' : 'error.main',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {value ? '✔️' : '❌'}
                      </Typography>
                    </Box>
                  ))} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.EMPTY_LISTING}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { search, page: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
