import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';
import { IDetailPatient } from '../patients/PatientsService';
import { IDetailExam } from '../exams/ExamsService';
import { IDetailLocation } from '../locations/LocationsService';

export interface IListSell {
  id: number;
  os?: number;
  date: Date;
  dateDelivery: Date;
  local: string;
  description?: string;
  patientName: string;
  exam: IDetailExam;
  location: IDetailLocation;
  status: number;
  totalSell: number;
  totalPay: number;
  remaining: number;
}

export interface IDetailSell {
  id: number;
  os?: number;
  exam_id: number;
  location_id: number;
  date: Date;
  dateDelivery: Date;
  image?: string;
  description?: string;
  status: number;
  exam?: IDetailExam;
  location?: IDetailLocation;
}

type TSellTotalCount = {
  data: IListSell[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = {},
  orderBy:
    | 'os'
    | 'date'
    | 'name'
    | 'local'
    | 'dateDelivery'
    | 'totalSell'
    | 'totalPay'
    | 'remaining'
    | '' = 'os', // Novo parâmetro para ordenar
  orderDirection: 'asc' | 'desc' | '' = '', // Direção da ordenação
  date = '', // filtro de data
  dateEnd = '',
  dateDelivery = '', //filtro de data de entrega
  dateDeliveryEnd = '', 
  status: number | null = null,
  id = 0
): Promise<TSellTotalCount | Error> => {
  try {
    let urlRelativa = `/sells?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;
    //if (all) urlRelativa += '&all';
    if (filter) urlRelativa += `&filter=${filter}`;
    if (id) urlRelativa += `&id=${id}`;
    if (orderBy) urlRelativa += `&orderBy=${orderBy}`;
    if (orderDirection) urlRelativa += `&orderDirection=${orderDirection}`;
    if (date) urlRelativa += `&date=${date}`;
    if (dateEnd) urlRelativa += `&dateEnd=${dateEnd}`;
    if (dateDelivery) urlRelativa += `&dateDelivery=${dateDelivery}`; 
    if (dateDeliveryEnd) urlRelativa += `&dateDeliveryEnd=${dateDeliveryEnd}`; 
    if (status) urlRelativa += `&status=${status}`; 


    const { data } = await Api().get(urlRelativa);

    if (data) {
      return {
        data: data.data, // Aqui você acessa o array de dados diretamente
        totalCount: data.total, // Pegando o total de registros da resposta
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailSell | Error> => {
  try {
    const { data } = await Api().get(`/sells/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailSell, 'id'>
): Promise<number | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    let imageUrl: string | undefined;

    if (
      dados.image &&
      !dados.image.startsWith('http') &&
      dados.image !== 'sell'
    ) {
      imageUrl = await handleImageUpload(dados.image, 'sell');
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
      dateDelivery:
        dados.dateDelivery instanceof Date
          ? dados.dateDelivery.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.dateDelivery, // Caso a data já esteja formatada
    };

    const { data } = await Api().post('/sells', dadosComUserID);

    if (data && data.sell.id) {
      return data.sell.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: IDetailSell
): Promise<void | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    let imageUrl: string | undefined;

    if (
      dados.image &&
      !dados.image.startsWith('http') &&
      dados.image !== 'sell'
    ) {
      imageUrl = await handleImageUpload(dados.image, 'sell');
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
      dateDelivery:
        dados.dateDelivery instanceof Date
          ? dados.dateDelivery.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.dateDelivery, // Caso a data já esteja formatada
    };

    await Api().put(`/sells/${id}`, dadosComUserID);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/sells/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const SellsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
