import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useDrawerContext } from '../shared/contexts';
import {
  Dashboard,
  DetailExams,
  DetailExpenses,
  DetailLocations,
  DetailPatients,
  DetailProducts,
  DetailSells,
  DetailUsers,
  ListExams,
  ListExpenses,
  ListLocations,
  ListPatients,
  ListProducts,
  ListUsers,
  ListPayments,
  ListSells,
} from '../pages';

export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions([
      {
        icon: 'home',
        path: '/pagina-inicial',
        label: 'Página inicial',
      },
      {
        icon: 'local_library',
        path: '/locations',
        label: 'Locais',
      },
      {
        icon: 'groups_2',
        path: '/patients',
        label: 'Pacientes',
      },
      {
        icon: 'content_paste',
        path: '/exams',
        label: 'Exames',
      },
      {
        icon: 'widgets',
        path: '/products',
        label: 'Produtos',
      },
      {
        icon: 'add_shopping_cart',
        path: '/sells',
        label: 'Vendas',
      },
      {
        icon: 'fact_check',
        path: '/expenses',
        label: 'Despesas',
      },
      {
        icon: 'insights',
        path: '/managePayments',
        label: 'Gerenciar Pagamentos',
      },
      {
        icon: 'person_add_alt',
        path: '/users',
        label: 'Usuário',
      },
    ]);
  }, []);

  return (
    <Routes>
      <Route path="/pagina-inicial" element={<Dashboard />} />

      <Route path="/exams" element={<ListExams />} />
      <Route path="/exams/detail/:id" element={<DetailExams />} />

      <Route path="/locations" element={<ListLocations />} />
      <Route path="/locations/detail/:id" element={<DetailLocations />} />

      <Route path="/patients" element={<ListPatients />} />
      <Route path="/patients/detail/:id" element={<DetailPatients />} />

      <Route path="/products" element={<ListProducts />} />
      <Route path="/products/detail/:id" element={<DetailProducts />} />

      <Route path="/sells" element={<ListSells />} />
      <Route path="/sells/detail/:id" element={<DetailSells />} />

      <Route path="/expenses" element={<ListExpenses />} />
      <Route path="/expenses/detail/:id" element={<DetailExpenses />} />

      <Route path="/users" element={<ListUsers />} />
      <Route path="/users/detail/:id" element={<DetailUsers />} />

      <Route path="/managePayments" element={<ListPayments />} />
      {/* <Route path="/users/detail/:id" element={<DetailUsers />} /> */}

      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
