import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
} else {
  console.error('Elemento "root" não encontrado no DOM.');
}
