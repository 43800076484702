import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Paper,
  LinearProgress,
  TableFooter,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  IListPayment,
  PaymentsService,
} from '../../shared/services/api/payments/PaymentsService';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { FilterBar } from '../components/FilterBar';
import { useSearchParams } from 'react-router-dom';
import { ToolsListing } from '../../shared/components';
import { useDebounce } from '../../shared/hooks';
import { SortTableHeader } from '../components/SortTableHeader';

export const ListPayments: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();

  const [rows, setRows] = useState<IListPayment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [editedRows, setEditedRows] = useState<
    {
      id: number;
      status?: number;
      date_pay?: string;
      payment_method?: number;
    }[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [datePay, setDatePay] = useState<string>(''); // Data de pagamento em massa
  const [status, setStatus] = useState<number>(); // Status de pagamento em massa
  const [paymentMethod, setPaymentMethod] = useState<number>(); // Método de pagamento em massa

  const filters = [
    { name: 'date', label: 'Data inicial', type: 'date' as const },
    { name: 'dateEnd', label: 'Data final', type: 'date' as const },
    {
      name: 'payment_method',
      label: 'Metodo',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Dinheiro' },
        { value: 2, label: 'Pix' },
        { value: 3, label: 'Cartão de Crédito' },
        { value: 4, label: 'Cartão de Débito' },
        { value: 5, label: 'Boleto' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select' as const,
      options: [
        { value: 0, label: 'Pendente' },
        { value: 1, label: 'Pago' },
      ],
    },
  ];

  const initialValues = {
    status: '',
    paymentMethod: '',
    date: '',
    dateEnd: '',
  };

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const orderBy = useMemo(
    () =>
      (searchParams.get('orderBy') as
        | 'id'
        | 'sell_id'
        | 'date'
        | 'date_pay'
        | 'status'
        | 'payment_method'
        | 'installments'
        | 'value') || 'id',
    [searchParams]
  );

  const orderDirection = useMemo(
    () => (searchParams.get('orderDirection') as 'asc' | 'desc') || 'asc',
    [searchParams]
  );

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    // Atualiza os parâmetros de pesquisa incluindo o novo filtro
    debounce(() => {
      const newSearchParams = {
        page,
        search,
        orderBy,
        orderDirection,
        status: Number(searchParams.get('status')),
        payment_method: Number(searchParams.get('payment_method')),
        date: searchParams.get('date') || '',
        dateEnd: searchParams.get('dateEnd') || '',
      };

      PaymentsService.getAll(
        newSearchParams.page,
        '',
        false,
        newSearchParams.search,
        newSearchParams.orderBy,
        newSearchParams.orderDirection,
        newSearchParams.status,
        newSearchParams.payment_method,
        newSearchParams.date,
        newSearchParams.dateEnd
      ).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [
    searchParams, // Isso garante que os filtros são observados
    page,
    orderBy,
    orderDirection,
  ]);

  const handleEditChange = (
    id: number,
    field: 'status' | 'date_pay' | 'payment_method',
    value: any
  ) => {
    setEditedRows((prev) => {
      const existing = prev.find((row) => row.id === id);
      if (existing) {
        return prev.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        );
      }
      return [...prev, { id, [field]: value }];
    });

    setRows((prev) =>
      prev.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleSaveChanges = () => {
    editedRows.forEach((row) => {
      PaymentsService.updateById(row.id, {
        ...row,
        date_pay: row.date_pay ? dayjs(row.date_pay).toDate() : undefined, // dayjs para evitar alteração de fuso horário
      }).then((result) => {
        if (result instanceof Error) {
          alert(`Erro ao atualizar ID ${row.id}: ${result.message}`);
        }
      });
    });
    setEditedRows([]);
    alert('Alterações salvas com sucesso!');
  };

  const handleSelectRow = (id: number) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyMassEdit = () => {
    setEditedRows((prevEditedRows) => {
      const updatedRows = selectedRows.map((id) => {
        const existing = prevEditedRows.find((row) => row.id === id);
        if (existing) {
          return {
            ...existing,
            date_pay: datePay, // Atualiza com os valores selecionados
            status: status,
            payment_method: paymentMethod,
          };
        }
        return {
          id,
          date_pay: datePay,
          status: status,
          payment_method: paymentMethod,
        };
      });

      return [
        ...prevEditedRows.filter((row) => !selectedRows.includes(row.id)),
        ...updatedRows,
      ];
    });

    setRows((prevRows) =>
      prevRows.map((row) => {
        if (selectedRows.includes(row.id)) {
          return {
            ...row,
            date_pay:
              datePay !== undefined ? dayjs(datePay).toDate() : row.date_pay, // Atualiza se tiver novo valor
            status: status !== undefined ? status : row.status, // Atualiza se tiver novo valor
            payment_method:
              paymentMethod !== undefined ? paymentMethod : row.payment_method, // Atualiza se tiver novo valor
          };
        }
        return row;
      })
    );

    alert('Edição em massa aplicada!');
  };

  const handleSortChange = (
    newOrderBy:
      | 'id'
      | 'sell_id'
      | 'date'
      | 'date_pay'
      | 'status'
      | 'payment_method'
      | 'installments'
      | 'value'
  ) => {
    const isAsc = orderBy === newOrderBy && orderDirection === 'asc';
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      orderBy: newOrderBy,
      orderDirection: isAsc ? 'desc' : 'asc',
      page: '1',
    });
  };

  const applyFilters = (values: Record<string, string | number | null>) => {
    const newSearchParams: Record<string, string> = {
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    };

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        newSearchParams[key] = String(value);
      } else {
        delete newSearchParams[key];
      }
    });

    setSearchParams(newSearchParams);
  };

  return (
    <LayoutBaseDePagina
      titulo="Gerenciar Pagamentos"
      barraDeFerramentas={
        <ToolsListing
          mostrarInputBusca
          textoDaBusca={search}
          mostrarBotaoNovo={false}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams(
              {
                ...Object.fromEntries(searchParams.entries()),
                search: texto,
                page: '1',
              },
              { replace: true }
            )
          }
        />
      }
    >
      {/* Chamando o FilterBar */}
      <FilterBar
        filters={filters}
        initialValues={initialValues}
        onApplyFilters={applyFilters}
        isLoading={isLoading}
      />
      <Box
        margin={1}
        display="flex"
        padding={1}
        justifyContent={'end'}
        component={Paper}
        variant="outlined"
      >
        {/* Edição em massa */}
        <TextField
          size="small"
          sx={{ mr: 2 }}
          type="date"
          label="Data de Pagamento"
          value={datePay}
          onChange={(e) => setDatePay(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          select
          size="small"
          sx={{ mr: 2 }}
          value={status}
          onChange={(e) => setStatus(Number(e.target.value))}
        >
          <MenuItem selected>Selecione o Status</MenuItem>
          <MenuItem value={0}>Pendente</MenuItem>
          <MenuItem value={1}>Pago</MenuItem>
        </TextField>
        <TextField
          select
          size="small"
          sx={{ mr: 2 }}
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(Number(e.target.value))}
        >
          <MenuItem selected>Selecione o Método</MenuItem>
          <MenuItem value={1}>Dinheiro</MenuItem>
          <MenuItem value={2}>Pix</MenuItem>
          <MenuItem value={3}>Cartão de Crédito</MenuItem>
          <MenuItem value={4}>Cartão de Débito</MenuItem>
          <MenuItem value={5}>Boleto</MenuItem>
        </TextField>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 2 }}
          onClick={handleApplyMassEdit}
          disabled={selectedRows.length === 0}
          size="small"
        >
          Aplicar Edição em Massa
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={editedRows.length === 0}
          onClick={handleSaveChanges}
          size="small"
        >
          Salvar Alterações
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <SortTableHeader
                orderBy={orderBy}
                orderDirection={orderDirection}
                onSortChange={handleSortChange}
                columns={[
                  { label: 'ID', value: 'id' },
                  { label: 'OS', value: 'id' },
                  { label: 'Método', value: 'payment_method' },
                  { label: 'Status', value: 'status' },
                  { label: 'Pagamento', value: 'installments' },
                  { label: 'Valor', value: 'value' },
                ]}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} selected={selectedRows.includes(row.id)}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleSelectRow(row.id)}
                  />
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.sell.os}</TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    value={row.payment_method}
                    onChange={(e) =>
                      handleEditChange(row.id, 'payment_method', e.target.value)
                    }
                  >
                    <MenuItem value={1}>Dinheiro</MenuItem>
                    <MenuItem value={2}>Pix</MenuItem>
                    <MenuItem value={3}>Cartão de Crédito</MenuItem>
                    <MenuItem value={4}>Cartão de Débito</MenuItem>
                    <MenuItem value={5}>Boleto</MenuItem>
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    value={row.status}
                    onChange={(e) =>
                      handleEditChange(row.id, 'status', e.target.value)
                    }
                  >
                    <MenuItem value={0}>Pendente</MenuItem>
                    <MenuItem value={1}>Pago</MenuItem>
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    size="small"
                    type="date"
                    value={dayjs(row.date_pay).format('YYYY-MM-DD')}
                    onChange={(e) =>
                      handleEditChange(row.id, 'date_pay', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  {row.value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {isLoading && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
