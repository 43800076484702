import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';

export interface IListTableLocationSell {
  id: number;
  local: string;
  totalCountSell: number;
  totalPayment: number;
  totalExpense: number;
  grossProfit: number;
  netProfit: number;
  receivables: number;
}

export interface IDetailDashboard {
  id: number;
  exam_id: number;
  location_id: number;
  date: Date;
  dateDelivery: Date;
  image?: string;
  status: number;
}

type TDashboardTotalCount = {
  data: IListTableLocationSell[];
  totalCount: number;
};

type TDashboardReportTotalCount = {
  data: any;
  totalCount: number;
};

const getlocationDashboard = async (
  page = 1,
  filter = '',
  date = '', // Novo parâmetro para o filtro de data
  orderBy:
    | 'id'
    | 'local'
    | 'totalCountSell'
    | 'totalPayment'
    | 'totalExpense'
    | 'grossProfit'
    | 'netProfit'
    | 'receivables' = 'id', // Novo parâmetro para ordenar
  orderDirection: 'asc' | 'desc' | '' = '', // Direção da ordenação
  id = 0
): Promise<TDashboardTotalCount | Error> => {
  try {
    //const urlRelativa = `/dashboards/locations?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&all`;
    let urlRelativa = `/dashboards/locations?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&all`;
    if (filter) urlRelativa += `&filter=${filter}`;
    if (id) urlRelativa += `&id=${id}`;
    if (orderBy) urlRelativa += `&orderBy=${orderBy}`;
    if (orderDirection) urlRelativa += `&orderDirection=${orderDirection}`;
    if (date) urlRelativa += `&date=${date}`; // Adicionando o filtro de data à URL

    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getSalesReport = async (
  filter = '',
  date = '',
  dateEnd = ''
): Promise<TDashboardReportTotalCount | Error> => {
  try {
    let urlRelativa = '/dashboards/reports/sales?all';
    if (filter) urlRelativa += `&filter=${filter}`;
    if (date) urlRelativa += `&date=${date}`;
    if (dateEnd) urlRelativa += `&dateEnd=${dateEnd}`;

    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getPaymentsReport = async (
  filter = '',
  date = ''
): Promise<TDashboardReportTotalCount | Error> => {
  try {
    let urlRelativa = '/dashboards/reports/payments?all';
    if (filter) urlRelativa += `&filter=${filter}`;
    if (date) urlRelativa += `&date=${date}`;

    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getExpensesReport = async (
  filter = '',
  date = ''
): Promise<TDashboardReportTotalCount | Error> => {
  try {
    let urlRelativa = '/dashboards/reports/expenses?all';
    if (filter) urlRelativa += `&filter=${filter}`;
    if (date) urlRelativa += `&date=${date}`;

    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

export const DashboardsService = {
  getlocationDashboard,
  getSalesReport,
  getPaymentsReport,
  getExpensesReport,
};
