import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';

interface ILensTreatment {
  antireflexo?: boolean;
  blue?: boolean;
  fotossensivel?: boolean;
  incolor?: boolean;
}

export interface IListProduct {
  id: number;
  date: Date;
  image?: string;
  description: string;
  status: number;
  type: number;
  priceSell: number;
  priceBuy: number;
  amount: number;

  lensType?: number;
  lensTreatment?: ILensTreatment;
  esf?: number;
  cil?: number;
  eix?: number;
  dnp?: number;
  alt?: number;
  add?: number;

  code?: string;
  materialType?: number;
  vertical?: number;
  horizontal?: number;
  bridge?: number;
  largDiagonal?: number;
}

export interface IDetailProduct {
  id: number;
  date: Date;
  image?: string;
  description: string;
  status: number;
  type: number;
  priceSell: number;
  priceBuy: number;
  amount: number;

  lensType?: number;
  lensTreatment?: ILensTreatment;
  esf?: number;
  cil?: number;
  eix?: number;
  dnp?: number;
  alt?: number;
  add?: number;

  code?: string;
  materialType?: number;
  vertical?: number;
  horizontal?: number;
  bridge?: number;
  largDiagonal?: number;
}

type TProductTotalCount = {
  data: IListProduct[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = '',
  status = 0,
  type = 0,
  materialType = 0,
  orderBy: 'id' | 'status' | 'type' | 'description' | 'code' = 'id', // Novo parâmetro para ordenar
  orderDirection: 'asc' | 'desc' = 'desc', // Direção da ordenação
  all = false,
  id = 0
): Promise<TProductTotalCount | Error> => {
  try {
    let urlRelativa = `/products?page=${page}&limit=10`;

    if (filter) urlRelativa += `&filter=${filter}`;
    if (status) urlRelativa += `&status=${status}`;
    if (materialType) urlRelativa += `&materialType=${materialType}`;
    if (type && type !== 100) urlRelativa += `&type=${type}`;
    if (all) urlRelativa += '&all';
    if (id) urlRelativa += `&id=${id}`;

    // Adicionando os parâmetros de ordenação
    urlRelativa += `&orderBy=${orderBy}&orderDirection=${orderDirection}`;

    const { data } = await Api().get(urlRelativa);

    if (data) {
      return {
        data: data.data,
        totalCount: data.total,
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailProduct | Error> => {
  try {
    const { data } = await Api().get(`/products/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailProduct, 'id'>
): Promise<number | Error> => {
  try {
    let imageUrl: string | undefined;

    if (dados.image && !dados.image.startsWith('http')) {
      imageUrl = await handleImageUpload(dados.image, 'product');
    }
    // Formatando a data para 'YYYY-MM-DD'
    const dadosComImage = {
      ...dados,
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };

    const { data } = await Api().post('/products', dadosComImage);

    if (data && data.product.id) {
      return data.product.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: IDetailProduct
): Promise<void | Error> => {
  try {
    let imageUrl: string | undefined;
    if (dados.image && !dados.image.startsWith('http')) {
      imageUrl = await handleImageUpload(dados.image, 'product');
    }

    const dadosComImage = {
      ...dados,
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };

    await Api().put(`/products/${id}`, dadosComImage);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/products/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

const updateStock = async (id: number) => {
  try {
    await Api().put(`/products/update-stock/${id}`);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data?.errors?.default ||
        'Erro ao atualizar o estoque.'
    );
  }
};

export const ProductsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  updateStock,
};
