import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Paper, LinearProgress, Typography, MenuItem, Select } from '@mui/material';
import { Environment } from '../../../../shared/environment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IPaymentData {
  year: number;
  month: number;
  total_pending: number;
  total_paid: number;
}

interface IChartPaymentsProps {
  isExternalLoading?: boolean;
  data?: IPaymentData[];
}

export const ChartPayments: React.FC<IChartPaymentsProps> = ({
  isExternalLoading = false,
  data = [],
}) => {
  const [chartData, setChartData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

  useEffect(() => {
    if (data.length > 0) {
      const years = Array.from(new Set(data.map((row) => row.year)));
      if (!years.includes(selectedYear)) {
        setSelectedYear(years[0]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      const filteredData = data.filter((row) => row.year === selectedYear);
      const months = Array.from({ length: 12 }, (_, i) => i + 1);
      const labels = months.map((month) => `${String(month).padStart(2, '0')}/${selectedYear}`);
      const totalPendingData = months.map((month) => filteredData.find((row) => row.month === month)?.total_pending || 0);
      const totalPaidData = months.map((month) => filteredData.find((row) => row.month === month)?.total_paid || 0);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Pagamentos Pendentes',
            data: totalPendingData,
            backgroundColor: 'rgba(237, 213, 78, 0.8)', // Amarelo
          },
          {
            label: 'Pagamentos Recebidos',
            data: totalPaidData,
            backgroundColor: 'rgba(91, 237, 78, 0.8)', // Verde
          },
        ],
      });
    }
  }, [data, selectedYear]);

  return (
    <Paper variant="outlined" sx={{ m: 1, p: 2, width: 'auto' }}>
      <Typography variant="h5" component="div" gutterBottom>
        Pagamentos por Mês
      </Typography>

      <Select
        value={selectedYear}
        onChange={(e) => setSelectedYear(Number(e.target.value))}
        sx={{ mb: 2 }}
      >
        {Array.from(new Set(data.map((row) => row.year))).map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>

      {loading && <LinearProgress variant="indeterminate" />}

      {chartData && (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: `Pagamentos Pendentes e Recebidos por Mês (${selectedYear})`,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    return `R$ ${value.toLocaleString('pt-BR')}`;
                  },
                },
              },
            },
          }}
        />
      )}

      {!chartData && data.length === 0 && !isExternalLoading && (
        <Typography variant="body1" color="textSecondary">
          {Environment.EMPTY_LISTING}
        </Typography>
      )}

      {loading && (
        <Typography variant="body2" color="textSecondary">
          Carregando dados...
        </Typography>
      )}
    </Paper>
  );
};
