import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';

export interface IListLocation {
  id: number;
  local: string;
  contact: string;
  fone: number;
}

export interface IDetailLocation {
  id: number;
  local: string;
  contact: string;
  fone: number;
  description?: string;
}

type TLocationTotalCount = {
  data: IListLocation[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = '',
  orderBy: 'id'  | 'local' | '' = '',  // Novo parâmetro para ordenar
  orderDirection: 'asc' | 'desc'| '' = '', // Direção da ordenação
  all = false,
  id = 0,
): Promise<TLocationTotalCount | Error> => {
  try {
    let urlRelativa = `/locations?page=${page}&limit=10`;
    if (all) urlRelativa += '&all';
    if (filter) urlRelativa += `&filter=${filter}`;
    if (id) urlRelativa += `&id=${id}`;
    if (orderBy) urlRelativa += `&orderBy=${orderBy}`;
    if (orderDirection) urlRelativa += `&orderDirection=${orderDirection}`;

    const { data } = await Api().get(urlRelativa);

    if (data) {
      return {
        data: data.data, // Aqui você acessa o array de dados diretamente
        totalCount: data.total, // Pegando o total de registros da resposta
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailLocation | Error> => {
  try {
    const { data } = await Api().get(`/locations/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailLocation, 'id'>
): Promise<number | Error> => {
  try {
    const { data } = await Api().post('/locations', dados);
    if (data.location) {
      return data.location.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: IDetailLocation
): Promise<any | Error> => {
  try {
    const { data } = await Api().put(`/locations/${id}`, dados);
    if (data && data.id) {
      return data.id; // Retorna o objeto de localização atualizado
    }
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/locations/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const LocationsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
