import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  Icon,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate

interface Filter {
  name: string;
  label: string;
  type: 'text' | 'date' | 'select';
  options?: { value: number | string; label: string }[]; // Apenas para selects
}

interface FilterBarProps {
  filters: Filter[];
  initialValues: Record<string, string | number | null>;
  onApplyFilters: (values: Record<string, string | number | null>) => void;
  isLoading?: boolean;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  filters,
  initialValues,
  onApplyFilters,
  isLoading,
}) => {
  const [filterValues, setFilterValues] = useState(initialValues);
  const navigate = useNavigate(); // Usando o hook useNavigate para navegação

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    if (!name) return;

    setFilterValues((prev) => ({
      ...prev,
      [name]: value as string | number | null, // Fazendo um cast seguro
    }));
  };

  const handleClearFilters = () => {
    setFilterValues(initialValues); // Reseta os filtros para os valores iniciais

    // Limpar os parâmetros de consulta da URL
    const newSearchParams = new URLSearchParams(window.location.search);
    filters.forEach((filter) => {
      newSearchParams.delete(filter.name); // Remove os parâmetros de filtro da URL
    });

    // Usando navigate para atualizar a URL sem os parâmetros de filtro
    navigate({ search: newSearchParams.toString() });
  };

  return (
    <Box
      margin={1}
      display="flex"
      padding={1}
      justifyContent={'end'}
      component={Paper}
      variant="outlined"
    >
      <Grid container spacing={1} alignItems="center">
        {filters.map((filter) => (
          <Grid item key={filter.name} xs={2}>
            {filter.type === 'select' ? (
              <TextField
                fullWidth
                select
                name={filter.name}
                label={filter.label}
                value={filterValues[filter.name] ?? ''}
                onChange={handleChange}
                disabled={isLoading}
                size="small"
              >
                {filter.options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                fullWidth
                type={filter.type}
                name={filter.name}
                label={filter.label}
                value={filterValues[filter.name] ?? ''}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                size="small"
                disabled={isLoading}
              />
            )}
          </Grid>
        ))}
        <Grid item xs={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onApplyFilters(filterValues)}
          >
            <Icon>search</Icon>
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            color="error"
            variant="outlined"
            onClick={handleClearFilters}
          >
            <Icon>search_off</Icon>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
