import { useEffect, useMemo, useState } from 'react';
import {
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  IListSell,
  SellsService,
} from '../../shared/services/api/sells/SellsService';
import { ToolsListing } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { Environment } from '../../shared/environment';
import { useDebounce } from '../../shared/hooks';
import dayjs from 'dayjs';
import { FilterBar } from '../components/FilterBar';
import { SortTableHeader } from '../components/SortTableHeader';

export const ListSells: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListSell[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const filters = [
    { name: 'date', label: 'Data inicial', type: 'date' as const },
    { name: 'dateEnd', label: 'Data final', type: 'date' as const },
    {
      name: 'dateDelivery',
      label: 'Data inicial Entrega',
      type: 'date' as const,
    },
    {
      name: 'dateDeliveryEnd',
      label: 'Data final Entrega',
      type: 'date' as const,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Fechado' },
        { value: 0, label: 'Aberto' },
      ],
    },
  ];

  const initialValues = {
    status: searchParams.get('status')
      ? Number(searchParams.get('status'))
      : null,
    date: '',
    dateEnd: '',
    dateDelivery: '',
    dateDeliveryEnd: '',
  };

  const orderBy = useMemo(
    () =>
      (searchParams.get('orderBy') as
        | 'os'
        | 'date'
        | 'name'
        | 'local'
        | 'dateDelivery'
        | 'totalSell'
        | 'totalPay'
        | 'remaining') || 'os',
    [searchParams]
  );
  const orderDirection = useMemo(
    () => (searchParams.get('orderDirection') as 'asc' | 'desc') || 'asc',
    [searchParams]
  );

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    // Atualiza os parâmetros de pesquisa incluindo o novo filtro
    debounce(() => {
      const newSearchParams = {
        page,
        search,
        orderBy,
        orderDirection,
        date: searchParams.get('date') || '',
        dateEnd: searchParams.get('dateEnd') || '',
        dateDelivery: searchParams.get('dateDelivery') || '',
        dateDeliveryEnd: searchParams.get('dateDeliveryEnd') || '',
        status: Number(searchParams.get('status')),
      };

      SellsService.getAll(
        newSearchParams.page,
        newSearchParams.search,
        newSearchParams.orderBy,
        newSearchParams.orderDirection,
        newSearchParams.date,
        newSearchParams.dateEnd,
        newSearchParams.dateDelivery,
        newSearchParams.dateDeliveryEnd,
        newSearchParams.status
      ).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [
    searchParams, // Isso garante que os filtros são observados
    page,
    orderBy,
    orderDirection,
  ]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      SellsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setRows((oldRows) => [
            ...oldRows.filter((oldRow) => oldRow.id !== id),
          ]);
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  const handleSortChange = (
    newOrderBy:
      | 'os'
      | 'date'
      | 'name'
      | 'local'
      | 'dateDelivery'
      | 'totalSell'
      | 'totalPay'
      | 'remaining'
  ) => {
    const isAsc = orderBy === newOrderBy && orderDirection === 'asc';
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      orderBy: newOrderBy,
      orderDirection: isAsc ? 'desc' : 'asc',
      page: '1',
    });
  };

  const applyFilters = (values: Record<string, string | number | null>) => {
    const newSearchParams: Record<string, string> = {
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    };

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        newSearchParams[key] = String(value);
      } else {
        delete newSearchParams[key];
      }
    });

    setSearchParams(newSearchParams);
  };

  return (
    <LayoutBaseDePagina
      titulo="Vendas"
      barraDeFerramentas={
        <ToolsListing
          mostrarInputBusca
          textoDaBusca={search}
          textoBotaoNovo="Nova"
          aoClicarEmNovo={() => navigate('/sells/detail/nova')}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams(
              {
                ...Object.fromEntries(searchParams.entries()),
                search: texto,
                page: '1',
              },
              { replace: true }
            )
          }
        />
      }
    >
      {/* Chamando o FilterBar */}
      <FilterBar
        filters={filters}
        initialValues={initialValues}
        onApplyFilters={applyFilters}
        isLoading={isLoading}
      />

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={100}>Ações</TableCell>
              <SortTableHeader
                orderBy={orderBy}
                orderDirection={orderDirection}
                onSortChange={handleSortChange}
                columns={[
                  { label: 'OS', value: 'os' },
                  { label: 'Nome', value: 'name' },
                  { label: 'Local', value: 'local' },
                  { label: 'Data', value: 'date' },
                  { label: 'Entrega', value: 'dateDelivery' },
                  { label: 'Total', value: 'totalSell' },
                  { label: 'Pago', value: 'totalPay' },
                  { label: 'Restante', value: 'remaining'},
                ]}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton size="small" onClick={() => handleDelete(row.id)}>
                    <Icon>delete</Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/sells/detail/${row.id}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.os}</TableCell>
                <TableCell>{row.exam?.patient?.name}</TableCell>
                <TableCell>{row.location.local}</TableCell>
                <TableCell>{dayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  {dayjs(row.dateDelivery).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  {row.totalSell.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>
                  {row.totalPay.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>
                  {row.remaining.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.EMPTY_LISTING}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { search, page: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
