import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ExpensesService } from '../../shared/services/api/expenses/ExpensesService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { VDateField } from '../../shared/forms/VDateField';
import { AutoCompleteLocation } from '../components/AutoCompleteLocation';

interface IFormData {
  location_id: number;
  pay_method: number;
  status: number;
  type: number;
  value: number;
  description: string;
  date: Date;
  date_pay: Date;
}
const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  location_id: yup.number().integer().required(),
  date: yup.date().required(),
  date_pay: yup.date().required(),
  description: yup.string().required().min(3).max(150),
  pay_method: yup.number().required(),
  status: yup.number().required(),
  type: yup.number().required(),
  value: yup.number().required(),
});

export const DetailExpenses: React.FC = () => {
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);

      ExpensesService.getById(Number(id)).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
          navigate('/expenses');
        } else {
          setName(result.description);
          formRef.current?.setData(result);
        }
      });
    } else {
      formRef.current?.setData({
        setName: '',
        setSerieNumber: '',
        setType: '',
        setDescription: '',
        date: new Date(), // Define uma data padrão
        date_pay: new Date(), // Define uma data padrão
      });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    console.log(dados);
    formValidationSchema
      .validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'nova') {
          ExpensesService.create(dadosValidados).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/expenses');
              } else {
                navigate(`/expenses/detail/${result}`);
              }
            }
          });
        } else {
          ExpensesService.updateById(Number(id), {
            id: Number(id),
            ...dadosValidados,
          }).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/expenses');
              }
            }
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ExpensesService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Registro apagado com sucesso!');
          navigate('/expenses');
        }
      });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'nova' ? 'New Expense' : name}
      barraDeFerramentas={
        <DetailTool
          textoBotaoNovo="Nova"
          mostrarBotaoSalvarEFechar
          mostrarBotaoNovo={id !== 'nova'}
          mostrarBotaoApagar={id !== 'nova'}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/expenses')}
          aoClicarEmApagar={() => handleDelete(Number(id))}
          aoClicarEmNovo={() => navigate('/expenses/detail/nova')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6">Registro de Despesas</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={2}
              paddingLeft={2}
              paddingTop={2}
              justifyContent={'center'}
              alignContent={'center'}
            >
              <Grid container item direction="row" spacing={2} justifyContent={'center'}>
                <Grid item xs={6} sm={6} md={4}>
                  <VDateField name="date" label="Data" />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <VDateField name="date_pay" label="Data Pagamento" />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={5}>
                <AutoCompleteLocation isExternalLoading={isLoading} />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3}>
                <VTextField
                  fullWidth
                  select
                  name="type"
                  label="Tipo"
                  disabled={isLoading}
                >
                  <MenuItem key="1" value="1">
                    Alimentação
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    Deslocamento
                  </MenuItem>
                  <MenuItem key="3" value="3">
                    Estadia
                  </MenuItem>
                  <MenuItem key="4" value="4">
                    Funcionario
                  </MenuItem>
                  <MenuItem key="5" value="5">
                    Outros
                  </MenuItem>
                </VTextField>
              </Grid>

              <Grid item xs={5} sm={4} md={2} lg={2}>
                <VTextField
                  fullWidth
                  select
                  name="status"
                  label="Status"
                  disabled={isLoading}
                >
                  <MenuItem key="2status" value="2">
                    Paga
                  </MenuItem>
                  <MenuItem key="1status" value="1">
                    Aberta
                  </MenuItem>
                </VTextField>
              </Grid>

              <Grid item xs={7}  md={7} lg={7}>
                <VTextField
                  fullWidth
                  select
                  name="pay_method"
                  label="Forma"
                  disabled={isLoading}
                >
                  <MenuItem key="1" value="1">
                    Pix
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    Cartão de Debito
                  </MenuItem>
                  <MenuItem key="3" value="3">
                    Cartão de Credito
                  </MenuItem>
                  <MenuItem key="4" value="4">
                    Boleto
                  </MenuItem>
                </VTextField>
              </Grid>

              <Grid item xs={5} md={4} lg={3}>
                <VTextField
                  fullWidth
                  name="value"
                  label="Valor"
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={11} lg={10}>
                <VTextField
                  fullWidth
                  name="description"
                  label="Descrição"
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
