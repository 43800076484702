import React from 'react';
import { TableCell, TableSortLabel } from '@mui/material';

interface SortTableHeaderProps<T> {
  orderBy: keyof T; // Usando a chave de T como tipo para 'orderBy'
  orderDirection: 'asc' | 'desc';
  onSortChange: (newOrderBy: keyof T) => void;
  columns: { label: string; value: keyof T }[]; // Permite passar um array de colunas
}

export const SortTableHeader = <T extends Record<string, any>>({
  orderBy,
  orderDirection,
  onSortChange,
  columns,
}: SortTableHeaderProps<T>) => {
  return (
    <>
      {columns.map(({ label, value }) => (
        <TableCell key={String(value)}>
          <TableSortLabel
            active={orderBy === value}
            direction={orderBy === value ? orderDirection : 'asc'}
            onClick={() => onSortChange(value)}
          >
            {label}
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
};
