import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Paper,
} from '@mui/material';
import { ExamsService } from '../../../shared/services/api/exams/ExamsService';
import PrescriptionTable from '../../sells/components/prescritionTable';
import dayjs from 'dayjs';

interface LastExamModalProps {
  open: boolean;
  onClose: () => void;
  patientId: number | null;
}

export const LastExamModal: React.FC<LastExamModalProps> = ({
  open,
  onClose,
  patientId,
}) => {
  const [exam, setExam] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && patientId) {
      setLoading(true);
      ExamsService.getLastExamByPatient(patientId)
        .then((result) => {
          if (!(result instanceof Error)) setExam(result);
        })
        .finally(() => setLoading(false));
    }
  }, [open, patientId]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Último Exame do Paciente</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <CircularProgress />
        ) : exam ? (
          <Paper variant="outlined">
            <Typography variant="body1" textAlign={'center'}>{dayjs(exam.date).format('DD/MM/YYYY')}</Typography>
            <Typography variant="body1">
              <PrescriptionTable
                ld={exam.ld}
                le={exam.le}
                pd={exam.pd}
                pe={exam.pe}
              />
            </Typography>
          </Paper>
        ) : (
          <Typography>Nenhum exame encontrado.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};
