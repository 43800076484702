import { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Icon,
  LinearProgress,
} from '@mui/material';
import {
  IListPayment,
  PaymentsService,
} from '../../../../shared/services/api/payments/PaymentsService';
import dayjs from 'dayjs';

interface ITablePaymentSellProps {
  sell_id?: number;
  onValuePaySell?: (total: number) => void;
}

export const TablePaymentSell: React.FC<ITablePaymentSellProps> = ({
  sell_id,
  onValuePaySell,
}) => {
  const [rows, setRows] = useState<IListPayment[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sell_id) {
      setIsLoading(true);
      PaymentsService.getAll(1, `${sell_id}`, true).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          console.log('qqqqq', result);
          
          setRows(result.data);
          const data = result.data;

          const totalSum = data.reduce(
            (acc, item) => acc + (item.value || 0),
            0
          );

          if (onValuePaySell) {
            onValuePaySell(totalSum);
          }
        }
      });
    }
  }, [sell_id]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      PaymentsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          const removedItem = rows.find((row) => row.id === id);

          if (removedItem) {
            const negativeValue = -Math.abs(Number(removedItem.value)); // Converte o valor para negativo
            if (onValuePaySell) {
              onValuePaySell(negativeValue);
            }
          }
          setRows((oldRows) => oldRows.filter((row) => row.id !== id));
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  return (
    <Grid container direction="column" spacing={2} p={3}>
      <Grid item xs={12}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      Nenhum pagamento registrado.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(row.id)}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {row.payment_method === 1
                          ? 'Dinheiro'
                          : row.payment_method === 2
                            ? 'Pix'
                            : row.payment_method === 3
                              ? 'Cartão de Crédito'
                              : row.payment_method === 4
                                ? 'Cartão de Débito'
                                : row.payment_method === 5
                                  ? 'Boleto'
                                  : row.payment_method}
                      </TableCell>
                      <TableCell>
                        {row.status === 1
                          ? 'Pago'
                          : row.status === 0
                            ? 'Pendente'
                            : row.status}
                      </TableCell>
                      <TableCell>
                        {dayjs(row.date_pay).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {row.value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
