import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ExamsService } from '../../shared/services/api/exams/ExamsService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { VDateField } from '../../shared/forms/VDateField';
import { AutoCompleteLocation } from '../components/AutoCompleteLocation';
import { PatientDetails } from './components/PatientDetails';
import { AutoCompletePatient } from './components/AutoCompletePatient';
import { Degree, IDegree } from './components/Degree';
import { Diagnosis } from './components/Diagnosis';
import { LensType } from '../components/LensType';
import { VImageField } from '../../shared/forms/VImageField';

interface IDiagnosis {
  miopia: boolean;
  hipermetropia: boolean;
  astigmatismo: boolean;
  presbiopia: boolean;
}

interface IFormData {
  date: Date;
  description: string;
  diagnosis: IDiagnosis;
  doctor: string;
  location_id: number;
  patient_id: number;
  image?: string;
  lensType: number;
  patientName?: string;

  le?: IDegree;
  ld?: IDegree;
  pe?: IDegree;
  pd?: IDegree;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  patient_id: yup.number().integer().required(),
  location_id: yup.number().integer().required(),
  date: yup.date().required(),
  doctor: yup.string().required().min(3).max(150),
  patientName: yup.string().optional(),
  image: yup.string().optional().max(300),
  diagnosis: yup
    .object()
    .shape({
      miopia: yup.boolean().required(),
      hipermetropia: yup.boolean().required(),
      astigmatismo: yup.boolean().required(),
      presbiopia: yup.boolean().required(),
    })
    .required(),
  lensType: yup.number().integer().required(),
  description: yup.string().required().min(3).max(150),
  le: yup
    .object()
    .shape({
      esf: yup.number().optional(),
      cil: yup.number().optional(),
      eix: yup.number().optional(),
      dnp: yup.number().optional(),
      alt: yup.number().optional(),
    })
    .optional(),
  ld: yup
    .object()
    .shape({
      esf: yup.number().optional(),
      cil: yup.number().optional(),
      eix: yup.number().optional(),
      dnp: yup.number().optional(),
      alt: yup.number().optional(),
    })
    .optional(),
  pe: yup
    .object()
    .shape({
      esf: yup.number().optional(),
      cil: yup.number().optional(),
      eix: yup.number().optional(),
      dnp: yup.number().optional(),
      alt: yup.number().optional(),
    })
    .optional(),
  pd: yup
    .object()
    .shape({
      esf: yup.number().optional(),
      cil: yup.number().optional(),
      eix: yup.number().optional(),
      dnp: yup.number().optional(),
      alt: yup.number().optional(),
    })
    .optional(),
});

export const DetailExams: React.FC = () => {
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [adicao, setAdicao] = useState(0);
  const [patient_id, setpatient_id] = useState<number | undefined>(undefined);
  const [degreeLongeDir, setDegreeLongeDir] = useState<IDegree>();
  const [degreeLongeEsq, setDegreeLongeEsq] = useState<IDegree>();
  const [calculatedDegreeDir, setCalculatedDegreeDir] = useState<IDegree>();
  const [calculatedDegreeEsq, setCalculatedDegreeEsq] = useState<IDegree>();

  // Função para atualizar o patient_id
  const handlePatientSelect = (id: number) => {
    setpatient_id(id);
  };

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);

      ExamsService.getById(Number(id)).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
          navigate('/exams');
        } else {
          console.log(result);
          setName(result.patientName || '');
          formRef.current?.setData({
            ...result,
          });
          setpatient_id(result.patient_id);
        }
      });
    } else {
      formRef.current?.setData({
        setDoctor: '',
        setpatient_id: '',
        setLocationID: '',
        setLe: '',
        setLd: '',
        setPe: '',
        setPd: '',
        setDate: '',
        setImage: '',
        diagnosis: {
          miopia: false,
          hipermetropia: false,
          astigmatismo: false,
          presbiopia: false,
        },
        setIndication: '',
        setDescription: '',
        date: new Date(), // Define uma data padrão
      });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    console.log('dados', dados);
    formValidationSchema
      .validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'nova') {
          ExamsService.create(dadosValidados).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/exams');
              } else {
                navigate(`/exams/detail/${result}`);
              }
            }
          });
        } else {
          ExamsService.updateById(Number(id), {
            id: Number(id),
            ...dadosValidados,
          }).then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/exams');
              }
            }
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        console.log('Erros de validação:', errors); // Adicione este log
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ExamsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Registro apagado com sucesso!');
          navigate('/exams');
        }
      });
    }
  };

  useEffect(() => {
    if (adicao !== 0) {
      console.log('adicao', adicao);
      console.log('degreeLongeDir', degreeLongeDir);
      console.log('degreeLongeEsq', degreeLongeEsq);
    }
  }, [adicao, degreeLongeDir, degreeLongeEsq]);

  const calcular = (
    adicao: number,
    degreeLongeDir?: IDegree,
    degreeLongeEsq?: IDegree
  ) => {
    const calcularGrau = (degree: IDegree | undefined, adicao: number) => {
      if (!degree) return undefined;

      return {
        ...degree,
        esf: degree.esf + adicao,
      };
    };

    const novoDegreeDir = calcularGrau(degreeLongeDir, adicao);
    const novoDegreeEsq = calcularGrau(degreeLongeEsq, adicao);

    return { novoDegreeDir, novoDegreeEsq };
  };

  /* Adição do grau no exame */
  const handleChangeAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value); // Converte a string para número
    const adicaoAtual = isNaN(value) ? 0 : value;
    setAdicao(adicaoAtual);

    // Chama a função centralizada para calcular
    const { novoDegreeDir, novoDegreeEsq } = calcular(
      adicaoAtual,
      degreeLongeDir,
      degreeLongeEsq
    );

    if (novoDegreeDir) setCalculatedDegreeDir(novoDegreeDir);
    if (novoDegreeEsq) setCalculatedDegreeEsq(novoDegreeEsq);
  };

  /* mudança do grau de longe, olho direto */

  const handleLongeDirChange = (newDegree: IDegree) => {
    setDegreeLongeDir(newDegree); // Atualiza os valores do grau do longe
  };

  /* mudança do grau de longe, olho esquerdo */
  const handleLongeEsqChange = (newDegree: IDegree) => {
    setDegreeLongeEsq(newDegree); // Atualiza os valores do grau do longe
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'nova' ? 'New Exam' : name}
      barraDeFerramentas={
        <DetailTool
          textoBotaoNovo="Nova"
          mostrarBotaoSalvarEFechar
          mostrarBotaoNovo={id !== 'nova'}
          mostrarBotaoApagar={id !== 'nova'}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/exams')}
          aoClicarEmApagar={() => handleDelete(Number(id))}
          aoClicarEmNovo={() => navigate('/exams/detail/nova')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6">Registro de Exames</Typography>
            </Grid>

            <Grid
              container
              item
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 6, md: 8 }}
            >
              <Grid item xs={12}>
                <PatientDetails patient_id={patient_id} />
              </Grid>
            </Grid>
            <Paper
              variant="outlined"
              sx={{ padding: 2, marginLeft: 2, marginTop: 1 }}
            >
              <Grid
                container
                direction="row"
                padding={2}
                spacing={2}
                justifyContent={'center'}
                alignContent={'center'}
              >
                <Grid item xs={12} md={4}>
                  <VImageField name="image" folder="exam" imageName={name} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent={'center'}
                    alignContent={'center'}
                  >
                    <Grid item xs={12} sm={12} md={8}>
                      <VTextField
                        fullWidth
                        name="doctor"
                        label="Dra/Dr."
                        disabled={isLoading}
                      />
                    </Grid>

                    <Grid item sm={12} md={4}>
                      <AutoCompleteLocation isExternalLoading={isLoading} />
                    </Grid>
                    <Grid item sm={12} md={8}>
                      <AutoCompletePatient
                        isExternalLoading={isLoading}
                        onPatientSelect={handlePatientSelect}
                      />
                    </Grid>

                    <Grid item sm={12} md={4}>
                      <VDateField name="date" label="Data" />
                    </Grid>
                    <Grid item xs={12} lg={10}>
                      <Diagnosis isExternalLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                      <LensType isExternalLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12}>
                      <VTextField
                        fullWidth
                        name="description"
                        label="Descrição"
                        disabled={isLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '8px',
                  }}
                >
                  Longe
                </Typography>
              </Grid>

              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Direito</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Degree
                    isExternalLoading={isLoading}
                    name="ld"
                    onChange={handleLongeDirChange}
                  />
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Esquerdo</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Degree
                    isExternalLoading={isLoading}
                    name="le"
                    onChange={handleLongeEsqChange}
                  />
                </Grid>
              </Grid>

              {/* Perto */}
              <Grid
                container
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginLeft={2}
                sx={{
                  backgroundColor: '#333',
                }}
              >
                <Typography
                  variant="subtitle2"
                  padding={1}
                  sx={{
                    color: '#fff',
                  }}
                >
                  Perto
                </Typography>
                <Grid
                  item
                  padding={1}
                  sx={{
                    color: '#fff',
                  }}
                  xs={3}
                  md={2}
                >
                  <VTextField
                    label="ADD"
                    name="add"
                    size="small"
                    onChange={handleChangeAdd}
                    type="number"
                    inputProps={{ step: 10 }}
                    sx={{
                      color: '#fff',
                      background: '#fff',
                    }}
                    fullWidth
                    focused
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Direito</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Degree
                    isExternalLoading={isLoading}
                    name="pd"
                    copyFrom={calculatedDegreeDir}
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Esquerdo</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Degree
                    isExternalLoading={isLoading}
                    name="pe"
                    copyFrom={calculatedDegreeEsq}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
