import { useEffect, useMemo, useState } from 'react';
import {
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  IListProduct,
  ProductsService,
} from '../../shared/services/api/products/ProductsService';
import { ToolsListing } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { Environment } from '../../shared/environment';
import { useDebounce } from '../../shared/hooks';
import { SortTableHeader } from '../components/SortTableHeader';
import { FilterBar } from '../components/FilterBar';

export const ListProducts: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const filters = [
    {
      name: 'type',
      label: 'Tipo',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Lente' },
        { value: 2, label: 'Armação' },
        { value: 3, label: 'Outro' },
      ],
    },
    {
      name: 'materialType',
      label: 'Tipo de Material',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Acetato' },
        { value: 2, label: 'Metal Fechado' },
        { value: 3, label: 'Fio de Nalon' },
        { value: 4, label: 'Balgrife' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Inativo' },
        { value: 2, label: 'Ativo' },
        { value: 3, label: 'Alocado' },
      ],
    },
  ];

  const initialValues = {
    status: searchParams.get('status')
      ? Number(searchParams.get('status'))
      : null,
    type: '',
    materialType: '',
  };

  const orderBy = useMemo(
    () =>
      (searchParams.get('orderBy') as
        | 'id'
        | 'status'
        | 'type'
        | 'description'
        | 'code') || 'id',
    [searchParams]
  );
  const orderDirection = useMemo(
    () => (searchParams.get('orderDirection') as 'asc' | 'desc') || 'asc',
    [searchParams]
  );

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    // Atualiza os parâmetros de pesquisa incluindo o novo filtro
    debounce(() => {
      const newSearchParams = {
        page,
        search,
        orderBy,
        orderDirection,
        status: Number(searchParams.get('status')),
        type: Number(searchParams.get('type')),
        materialType: Number(searchParams.get('materialType')),
      };

      ProductsService.getAll(
        newSearchParams.page,
        newSearchParams.search,
        newSearchParams.status,
        newSearchParams.type,
        newSearchParams.materialType,
        newSearchParams.orderBy,
        newSearchParams.orderDirection
      ).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [
    searchParams, // Isso garante que os filtros são observados
    page,
    orderBy,
    orderDirection,
  ]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ProductsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setRows((oldRows) => [
            ...oldRows.filter((oldRow) => oldRow.id !== id),
          ]);
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  const handleSortChange = (
    newOrderBy: 'id' | 'status' | 'type' | 'description' | 'code'
  ) => {
    const isAsc = orderBy === newOrderBy && orderDirection === 'asc';
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      orderBy: newOrderBy,
      orderDirection: isAsc ? 'desc' : 'asc',
      page: '1',
    });
  };

  const applyFilters = (values: Record<string, string | number | null>) => {
    const newSearchParams: Record<string, string> = {
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    };

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        newSearchParams[key] = String(value);
      } else {
        delete newSearchParams[key];
      }
    });

    setSearchParams(newSearchParams);
  };

  return (
    <LayoutBaseDePagina
      titulo="Produtos"
      barraDeFerramentas={
        <ToolsListing
          mostrarInputBusca
          textoDaBusca={search}
          textoBotaoNovo="Nova"
          aoClicarEmNovo={() => navigate('/products/detail/nova')}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams(
              {
                ...Object.fromEntries(searchParams.entries()),
                search: texto,
                page: '1',
              },
              { replace: true }
            )
          }
        />
      }
    >
      {/* Chamando o FilterBar */}
      <FilterBar
        filters={filters}
        initialValues={initialValues}
        onApplyFilters={applyFilters}
        isLoading={isLoading}
      />

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={100}>Ações</TableCell>
              <SortTableHeader
                orderBy={orderBy}
                orderDirection={orderDirection}
                onSortChange={handleSortChange}
                columns={[
                  { label: 'ID', value: 'id' },
                  { label: 'Status', value: 'status' },
                  { label: 'Tipo', value: 'type' },
                  { label: 'Descrição', value: 'description' },
                  { label: 'Cod.', value: 'code' },
                ]}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton size="small" onClick={() => handleDelete(row.id)}>
                    <Icon>delete</Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/products/detail/${row.id}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {row.status === 2
                    ? 'Ativo'
                    : row.status === 1
                      ? 'Inativo'
                      : 'Alocado'}
                </TableCell>
                <TableCell>
                  {row.type === 1
                    ? 'Lente'
                    : row.type === 2
                      ? 'Armação'
                      : 'Outro'}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.code}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.EMPTY_LISTING}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { search, page: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
