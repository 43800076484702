import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  IListExpense,
  ExpensesService,
} from '../../shared/services/api/expenses/ExpensesService';
import { ToolsListing } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { Environment } from '../../shared/environment';
import { useDebounce } from '../../shared/hooks';
import dayjs from 'dayjs';
import { SortTableHeader } from '../components/SortTableHeader';
import { FilterBar } from '../components/FilterBar';

export const ListExpenses: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListExpense[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const filters = [
    { name: 'date', label: 'Data inicial', type: 'date' as const },
    { name: 'dateEnd', label: 'Data final', type: 'date' as const },
    {
      name: 'type',
      label: 'Tipo',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Alimentação' },
        { value: 2, label: 'Deslocamento' },
        { value: 3, label: 'Estadia' },
        { value: 4, label: 'Funcionario' },
        { value: 5, label: 'Outros' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select' as const,
      options: [
        { value: 1, label: 'Aberto' },
        { value: 2, label: 'Pago' },
      ],
    },
  ];

  const initialValues = {
    status: searchParams.get('status')
      ? Number(searchParams.get('status'))
      : null,
    type: '',
    date: '',
    dateEnd: '',
  };

  const orderBy = useMemo(
    () =>
      (searchParams.get('orderBy') as
        | 'local'
        | 'status'
        | 'type'
        | 'value'
        | 'date_pay') || 'date_pay',
    [searchParams]
  );
  const orderDirection = useMemo(
    () => (searchParams.get('orderDirection') as 'asc' | 'desc') || 'asc',
    [searchParams]
  );

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    // Atualiza os parâmetros de pesquisa incluindo o novo filtro
    debounce(() => {
      const newSearchParams = {
        page,
        search,
        orderBy,
        orderDirection,
        status: Number(searchParams.get('status')),
        type: Number(searchParams.get('type')),
        date: searchParams.get('date') || '',
        dateEnd: searchParams.get('dateEnd') || '',
      };

      ExpensesService.getAll(
        newSearchParams.page,
        newSearchParams.search,
        newSearchParams.status,
        newSearchParams.type,
        newSearchParams.orderBy,
        newSearchParams.orderDirection,
        newSearchParams.date,
        newSearchParams.dateEnd,
      ).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [
    searchParams, // Isso garante que os filtros são observados
    page,
    orderBy,
    orderDirection,
  ]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ExpensesService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setRows((oldRows) => [
            ...oldRows.filter((oldRow) => oldRow.id !== id),
          ]);
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  const handleSortChange = (
    newOrderBy: 'local' | 'status' | 'type' | 'value' | 'date_pay'
  ) => {
    const isAsc = orderBy === newOrderBy && orderDirection === 'asc';
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      orderBy: newOrderBy,
      orderDirection: isAsc ? 'desc' : 'asc',
      page: '1',
    });
  };

  const applyFilters = (values: Record<string, string | number | null>) => {
    const newSearchParams: Record<string, string> = {
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    };

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        newSearchParams[key] = String(value);
      } else {
        delete newSearchParams[key];
      }
    });

    setSearchParams(newSearchParams);
  };

  return (
    <LayoutBaseDePagina
      titulo="Despesas"
      barraDeFerramentas={
        <ToolsListing
          mostrarInputBusca
          textoDaBusca={search}
          textoBotaoNovo="Nova"
          aoClicarEmNovo={() => navigate('/expenses/detail/nova')}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams(
              {
                ...Object.fromEntries(searchParams.entries()),
                search: texto,
                page: '1',
              },
              { replace: true }
            )
          }
        />
      }
    >
      {/* Chamando o FilterBar */}
      <FilterBar
        filters={filters}
        initialValues={initialValues}
        onApplyFilters={applyFilters}
        isLoading={isLoading}
      />

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={100}>Ações</TableCell>
              <SortTableHeader
                orderBy={orderBy}
                orderDirection={orderDirection}
                onSortChange={handleSortChange}
                columns={[
                  { label: 'Local', value: 'local' },
                  { label: 'Status', value: 'status' },
                  { label: 'Tipo', value: 'type' },
                  { label: 'Valor', value: 'value' },
                  { label: 'Data', value: 'date_pay' },
                ]}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton size="small" onClick={() => handleDelete(row.id)}>
                    <Icon>delete</Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/expenses/detail/${row.id}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.local}</TableCell>
                <TableCell>
                  {row.status === 1 ? 'Aberto' : row.status === 2 ? 'Pago' : ''}
                </TableCell>
                <TableCell>
                  {row.type === 1
                    ? 'Alimentação'
                    : row.type === 2
                      ? 'Deslocamento'
                      : row.type === 3
                        ? 'Estadia'
                        : row.type === 4
                          ? 'Funcionario'
                          : row.type === 5
                            ? 'Outros'
                            : row.type}
                </TableCell>
                <TableCell>
                  {row.value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>
                  {dayjs(row.date_pay).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.EMPTY_LISTING}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { search, page: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
