import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  Paper,
  LinearProgress,
  Typography,
  MenuItem,
  Grid,
  TextField,
} from '@mui/material';
import { Environment } from '../../../../shared/environment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ISaleData {
  month: number;
  totalSales: number;
  totalPaid: number;
  remaining: number;
}

interface IChartSalesProps {
  isExternalLoading?: boolean;
  data?: { year: number; sales: ISaleData[] }[];
  totalCount: number;
}

export const ChartSales: React.FC<IChartSalesProps> = ({
  isExternalLoading = false,
  data,
  totalCount,
}) => {
  const [chartData, setChartData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [yearFilter, setYearFilter] = useState<number | undefined>(
    new Date().getFullYear()
  );

  useEffect(() => {
    if (data) {
      setLoading(true);

      const salesByYear: {
        [key: number]: {
          totalSales: number[];
          totalPaid: number[];
          remaining: number[];
        };
      } = {};

      // Inicializa estrutura de dados
      data.forEach((row) => {
        const year = row.year;
        if (!salesByYear[year]) {
          salesByYear[year] = {
            totalSales: Array(12).fill(0),
            totalPaid: Array(12).fill(0),
            remaining: Array(12).fill(0),
          };
        }

        row.sales.forEach((sale) => {
          const month = sale.month - 1; // Ajuste de índice (0 a 11)
          salesByYear[year].totalSales[month] = sale.totalSales || 0;
          salesByYear[year].totalPaid[month] = sale.totalPaid || 0;
          salesByYear[year].remaining[month] = sale.remaining || 0;
        });
      });

      // Obtém os anos disponíveis
      const years = Object.keys(salesByYear).map((year) => parseInt(year));

      // Dados do ano filtrado ou todos os anos juntos
      const selectedYearData = yearFilter
        ? salesByYear[yearFilter]
        : Object.values(salesByYear).reduce(
          (acc, yearData) => {
            return {
              totalSales: acc.totalSales.map(
                (val, idx) => val + yearData.totalSales[idx]
              ),
              totalPaid: acc.totalPaid.map(
                (val, idx) => val + yearData.totalPaid[idx]
              ),
              remaining: acc.remaining.map(
                (val, idx) => val + yearData.remaining[idx]
              ),
            };
          },
          {
            totalSales: Array(12).fill(0),
            totalPaid: Array(12).fill(0),
            remaining: Array(12).fill(0),
          }
        );

      // Labels para os meses
      const labels = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ];

      // Configurar os dados do gráfico
      setChartData({
        labels,
        datasets: [
          {
            label: 'Vendas Totais',
            data: selectedYearData?.totalSales,
            backgroundColor: 'rgba(54, 162, 235, 0.7)', // Azul
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
          {
            label: 'Pagamentos Recebidos',
            data: selectedYearData?.totalPaid,
            backgroundColor: 'rgba(75, 192, 75, 0.7)', // Verde
            borderColor: 'rgba(75, 192, 75, 1)',
            borderWidth: 1,
          },
          {
            label: 'Saldo Restante',
            data: selectedYearData?.remaining,
            backgroundColor: 'rgba(255, 99, 132, 0.7)', // Vermelho
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      });

      setLoading(false);
    }
  }, [data, yearFilter]);

  const handleYearChange = (value: number) => {
    setYearFilter(value);
  };

  return (
    <Paper variant="outlined" sx={{ m: 1, p: 2, width: '60%' }}>
      <Typography variant="h5" component="div" gutterBottom>
        Vendas por Mês e Ano
      </Typography>

      {loading && <LinearProgress variant="indeterminate" />}

      {/* Filtro de Ano */}
      <Grid container item display="flex" justifyContent={'end'}>
        <Grid item paddingRight={2} xs={2}>
          <TextField
            select
            value={yearFilter}
            label="Ano"
            onChange={(e) => handleYearChange(Number(e.target.value))}
            size="small"
            fullWidth
          >
            {data?.map((row) => (
              <MenuItem key={row.year} value={row.year}>
                {row.year}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {chartData ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: 'top' },
              title: {
                display: true,
                text: `Vendas por Mês de ${yearFilter}`,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    return `R$ ${value.toLocaleString('pt-BR')}`;
                  },
                },
              },
            },
          }}
        />
      ) : (
        totalCount === 0 &&
        !isExternalLoading && (
          <Typography variant="body1" color="textSecondary">
            {Environment.EMPTY_LISTING}
          </Typography>
        )
      )}

      {loading && (
        <Typography variant="body2" color="textSecondary">
          Carregando dados...
        </Typography>
      )}
    </Paper>
  );
};
